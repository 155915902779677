import React from 'react';
import './dotAnimation.scss';

export default function Dots(props) {
    
    const colors = ['white']

    let dotCount = props.dotCount;

    const dotList = () => {
        let dots = [];

        for (let i=0; i<= dotCount; i++) {
            const color = colors[Math.floor(Math.random()*3)];
            const posX = Math.floor(Math.random()*100);
            const posY = Math.floor(Math.random()*100);

            dots.push(
                <div className="overlay__dot" key={i}
                    style={{backgroundColor: color,
                        top: `${posY}%`,
                        left: `${posX}%`}} />
            )
        }

        return dots;
    }


    return (
       <div className="overlayWrapper">
           <div className="overlay">
               {dotList()}
               <canvas>
                   {/* canvas stuff goes here */} 
               </canvas>
           </div>
       </div>
    )
}

