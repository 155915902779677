import React from 'react';
import './App.scss';
import { Link } from "react-scroll";
import { data } from "./assets/data/info.json"

import arrow from './assets/icons/next.png'
import self from './assets/images/me.png';
import dehanee from './assets/images/dehanee.png';
import htmlLogo from './assets/icons/html-logo.png';
import nodeLogo from './assets/icons/node-logo.png';
import reactLogo from './assets/icons/react.png';
import mySqlLogo from './assets/icons/mysql-logo.png';
import snakeGif from './assets/gifs/snake.gif';
import kanbanPic from './assets/images/kanban.png';
import linkedinLogo from './assets/icons/linkedin.png';
import githubLogo from './assets/icons/githublogo.png';
import mcsXbstn from './assets/images/microsoftXbrainstation.png';
import earthDefence from './assets/images/earthDefence.png';
import Modall from './components/Modals';

import Dots from './components/dotAnimation/dotAnimation';

// const [modalShow, setModalShow] = React.useState(false);

export default class App extends React.Component {

  state = {
    isHovered: "header__arrow",
    modalShow: false,
    info: {}
  }
  
  addAnimation = () => {
    if (this.state.isHovered === "header__arrow") {
      this.setState({ isHovered: "header__arrow header__arrow--animation" }) 
    }
  }

  componentDidMount() {
    let num = Math.floor(Math.random() * 10) + 300
    this.setState({dotCount: num})
  }


  

  render() {


    return (
      <div className="App">

        {/* <OverlayCanvas /> */}

        <header className="header">
          <Dots dotCount={this.state.dotCount}/>

          <div className="header__info" >
            <h1 className="header__title">Hello, I'm <span className="header--colored">Nelson Mendez</span>.</h1>
            <h1 className="header__title">I'm a full-stack web developer.</h1>
            <Link activeClass="active" to="about" spy={true} smooth={true} offset={5} duration={500} onSetActive={this.handleSetActive}>
              <button className="header__button" onMouseEnter={this.addAnimation}>
                Learn more about me
                <img src={arrow} className={this.state.isHovered} alt=""/>
              </button>
            </Link>


          </div>
        </header>
  
        <div name="about" className="about">
  
          <h2 className="about__title">ABOUT</h2>
          
          <div className="about__me">

            <div className="about__bio">
              <img src={self} className="about__image" alt="Nelson"/>
              <h3 className="about__textHeader">Who am I? Glad you asked!</h3>
              <p className="about__text">My name is Nelson Mendez. </p>
              <p className="about__text">Originally an engineer, after completing my diploma at BrainStation, I'm now also a web developer. </p>
              <p className="about__text">I have a passion for technology, I'm always learning new things, and am currently working on several projects 
                while looking for work as a jr developer.</p>

              <Link activeClass="active" to="contact" spy={true} smooth={true} offset={5} duration={500} onSetActive={this.handleSetActive}>
                <p className="about__contact">Lets get in touch!</p>
              </Link>
            </div>

            <div className="skills"> 
              <div className="skills__big" >
                <img src={htmlLogo} className="skills__big" alt="" />
              </div>

              <div className="skills__small">
                <img src={nodeLogo} className="skills__node" alt="" />  
                <img src={reactLogo} className="skills__react" alt="" />  
                <img src={mySqlLogo} className="skills__sql" alt="" />
              </div>
            </div>            
          </div>
        </div>
  
          <div className="projects">
            <h2 className="projects__title">PROJECTS</h2>
            <div className="projects__projectList">
              <div className="projects__card" >
                <button className="projects__button" onClick={() => this.setState({modalShow: true, info: data.kanban})}>
                  <img src={kanbanPic} className="projects__kanban projects__button" alt=""/>
                </button>
              </div>

              <div className="projects__card" >
                <button className="projects__button" onClick={() => this.setState({modalShow: true, info: data.battleSnake})}>
                  <img src={snakeGif} alt="snake game"/>
                </button>
              </div>

              <div className="projects__card" >
                <button className="projects__button" onClick={() => this.setState({modalShow: true, info: data.dehanee})}> 
                  <img src={dehanee} className="projects__dehanee" alt=""/>
                </button>
              </div>

              <div className="projects__card" >
                <button className="projects__button" onClick={() => this.setState({modalShow: true, info: data.microsoft})}> 
                  <img src={mcsXbstn} className="projects__microsoft" alt=""/>
                </button>
              </div>

              <div className="projects__card" >
                <button className="projects__button" onClick={() => this.setState({modalShow: true, info: data.earthDefence})}> 
                  <img src={earthDefence} className="projects__earthDefence" alt=""/>
                </button>
              </div>

              <Modall 
              show={this.state.modalShow}
              onHide={() => this.setState({modalShow: false})}
              contents={this.state.info}
              />

            </div>

          </div>

          <footer name="contact" className="contact"> 
            <h2 className="contact__title">CONTACT</h2>
            <p className="contact__question">Have a question? Want to work together?</p>
            {/* <p className="contact__question">Email me at n</p> */}


            <div className="contact__clickables">
              <a  href="https://www.linkedin.com/in/nelson-mendez-a6923176/">
                <img src={linkedinLogo} className="contact__logo" alt="" />
              </a>
              <a href="https://www.github.com/Nelson-Mendez">
                <img src={githubLogo} className="contact__logo" alt="" />
              </a>
            </div>"
          </footer>


      </div>
    );
  }
}

